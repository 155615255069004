<template>
    <div class="navbar-space"></div>
    <div class="navbar">
        <img src="https://via.placeholder.com/40" alt="Logo" class="logo">
        <div class="icon-btns">
            <div class="icon-btn">
                <i class="fas fa-search"></i>
            </div>
            <div class="icon-btn">
                <i class="fas fa-bell"></i>
            </div>
            <div class="icon-btn">
                <i class="fas fa-shopping-cart"></i>
            </div>
            <div class="icon-btn" id="menu-btn" @click="toggleSPopup">
                <i class="fas fa-bars"></i>
            </div>
        </div>
    </div>
    <div class="SPopup" id="SPopup" v-show="isSPopupVisible">
        <div class="overlay"></div>
        <div class="SPopup-content">
            <span class="close-btn" id="SPopup-close" @click="closeSPopup">&times;</span>
            <h2>Menu</h2>
            <ul>
                <li><a href="#">Opção 1</a></li>
                <li><a href="#">Opção 2</a></li>
                <li><a href="#">Opção 3</a></li>
                <li><a href="#">Opção 4</a></li>
            </ul>
            <button class="btn-theme-toggle" id="theme-toggle" @click="toggleTheme">Alternar Tema</button>
        </div>
    </div>
</template>

<script>
export default {
  name: 'SNavbar',
  data() {
    return {
      isDarkTheme: false, // Variável para controlar o estado do tema,
      isSPopupVisible: false
    };
  },
  methods: {
    toggleTheme() {
      this.isDarkTheme = !this.isDarkTheme;
      document.body.classList.toggle('dark-theme', this.isDarkTheme);
    },
    toggleSPopup() {
      this.isSPopupVisible = !this.isSPopupVisible;
      console.log("Nunca sente medo!");
      
    },
    closeSPopup() {
      this.isSPopupVisible = false;
    }
  },
  mounted() {
    // Opcional: Se você quiser manter o tema entre recarregamentos, pode verificar o estado atual e aplicar a classe
    this.isDarkTheme = document.body.classList.contains('dark-theme');
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}


.navbar-space {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
}

.navbar .logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.navbar .icon-btns {
    display: flex;
    align-items: center;
}

.navbar .icon-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: #555;
    color: white;
    font-size: 18px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.navbar .icon-btn:hover {
    background-color: #777;
    transform: scale(1.1);
}

.SPopup {
    position: fixed;
    top: 10%;
    right: 20px;
    width: 200px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    }

.dark-theme .SPopup {
    position: fixed;
    top: 10%;
    right: 20px;
    width: 200px;
    background-color: #444;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    }

.SPopup .SPopup-content {
    position: relative;
}

.SPopup .SPopup-content h2 {
    margin: 0;
    font-size: 18px;
}

.SPopup .SPopup-content .btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
}

.SPopup .SPopup-content .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.SPopup .SPopup-content .close-btn:hover {
    color: #f00;
}
</style>
