<template>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap"
    rel="stylesheet"
  />
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
  />
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
  />
  <SNavbar />
  <div class="content">
    <h1>SUNRISE STUDIOS</h1>
    <h2>Bem vindo ao espaço Sunrise!</h2>
  </div>

  <div class="section">
    <div class="image-text-container">
      <img src="https://via.placeholder.com/750x400" alt="Imagem de exemplo" />
      <div class="overlay-text">
        <h1>Addons</h1>
        <p>Descrição breve sobre a imagem.</p>
        <a
          href="#addons-texturas"
          style="
            display: inline-block;
            padding: 10px 20px;
            background-color: white;
            color: black;
            text-decoration: none;
            border: 1px solid #ccc;
            border-radius: 10px;
          "
          >Ver mais</a
        >
      </div>
    </div>
    <div class="image-text-container-i">
      <img src="https://via.placeholder.com/750x400" alt="Imagem de exemplo" />
      <div class="overlay-text-i">
        <h1>Games</h1>
        <p>Descrição breve sobre a imagem.</p>
        <a
          href="#games"
          style="
            display: inline-block;
            padding: 10px 20px;
            background-color: white;
            color: black;
            text-decoration: none;
            border: 1px solid #ccc;
            border-radius: 10px;
          "
          >Ver mais</a
        >
      </div>
    </div>
  </div>

  <div class="section">
    <h3>Ultimas noticias</h3>
    <div class="scroll-container">
      <div class="scroll-item-news">
        <div class="card">
          <img src="https://via.placeholder.com/208x117" alt="Card Image" />
          <h4>Título da noticia</h4>
          <p>Descrição breve sobre da noticia naqueles pike.</p>
        </div>
      </div>
      <div class="scroll-item-news">
        <div class="card">
          <img src="https://via.placeholder.com/208x117" alt="Card Image" />
          <h4>Título da noticia</h4>
          <p>Descrição breve sobre da noticia naqueles pike.</p>
        </div>
      </div>
      <div class="scroll-item-news">
        <div class="card">
          <img src="https://via.placeholder.com/208x117" alt="Card Image" />
          <h4>Título da noticia</h4>
          <p>Descrição breve sobre da noticia naqueles pike.</p>
        </div>
      </div>
    </div>
  </div>

  <div id="addons-texturas" class="section">
    <h3>Addons e texturas</h3>
    <p>
      Veja os addons, mods, texturas e muito mais para melhorar a sua jogatina de
      Minecraft Bedrock!
    </p>
    <div class="scroll-container">
      <div class="card-img scroll-item">
        <img
          src="https://api.sunrise-studio.site/assets/vampire-craft-logo.png"
          alt="Card Image vampire craft"
          @click="toggleAddonCardVampireCraft"
        />
      </div>
      <div class="card-img scroll-item">
        <img src="https://via.placeholder.com/117x208" alt="Card Image" @click="toggleAddonCardNone"/>
      </div>
      <div class="card-img scroll-item">
        <img src="https://via.placeholder.com/117x208" alt="Card Image" />
      </div>
    </div>
    <div class="section">
      <div class="scroll-item-none just-card" v-show="isAddonCard=='none'">
        <h4>Minecraft addons</h4>
        <p>Fazemos addons/mods para o Minecraft Bedrock desde de 2019.</p>
      </div>
      <div class="scroll-item-none just-card" v-show="isAddonCard=='vampire-craft'">
        <h4>Vampire craft - Sede de sangue!</h4>
        <p>Have you ever wanted to be a vampire? Now you can! The vampire craft addon is inspired by a famous java mod with the same theme. In it it is possible to transform into a vampire, drink blood, unlock skills and among of other things.</p>
      </div>
    </div>
  </div>

  <div id="games" class="section">
    <h3>Jogos!</h3>
    <p>
      Veja os addons, mods, texturas e muito mais para melhorar a sua jogatina de
      Minecraft Bedrock!
    </p>
        <div class="scroll-container">
      <div class="card-img scroll-item-large">
        <img src="https://via.placeholder.com/316x178" alt="Card Image vampire craft"/>
      </div>
      <div class="card-img scroll-item-large">
        <img src="https://via.placeholder.com/316x178" alt="Card Image"/>
      </div>
      <div class="card-img scroll-item-large">
        <img src="https://via.placeholder.com/316x178" alt="Card Image" />
      </div>
    </div>
  </div>

  <div class="section">
    <h3>Nossos Serviços</h3>
    <div class="cards">
      <div class="card">
        <h4>Minecraft addons</h4>
        <p>Fazemos addons/mods para o Minecraft Bedrock desde de 2019.</p>
      </div>
      <div class="card">
        <h4>Título do Card 2</h4>
        <p>Descrição breve sobre o serviço ou produto oferecido no Card 2.</p>
      </div>
    </div>
  </div>

  <SFooter />
</template>

<script>
import SNavbar from "./components/navbar.vue";
import SFooter from "./components/footer.vue";

export default {
  name: "App",
  data() {
    return {
      isAddonCard: "none"
    };
  },
  methods: {
    toggleAddonCardVampireCraft() {
      this.isAddonCard = "vampire-craft";
    },
    toggleAddonCardNone() {
      this.isAddonCard = "none";
    },
  },
  components: {
    SNavbar,
    SFooter,
  },
};
</script>

<style>
#app {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #f0f0f0;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #f0f0f0;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.content {
  justify-content: center;
  align-items: center;
  padding: 20px;
  animation: fadeIn 1s ease;
}

.content h1 {
  font-size: 36px;
  color: #333;
  font-weight: 700;
  margin: 0;
}

.content h2 {
  font-size: 18px;
  color: #666;
  margin-top: 5px;
}

.section {
  padding: 50px;
}

.section h3 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  position: relative;
  animation: slideIn 0.5s ease;
}

.section h3::after {
  content: "";
  display: block;
  width: 50px;
  height: 4px;
  background-color: #333;
  margin: 10px auto;
}

.slider {
  background-color: #ddd;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #666;
  animation: fadeIn 1s ease;
}

.cards {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  gap: 20px;
  flex-wrap: wrap;
}

.card-img {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: left;
  transition: transform 0.3s ease;
}

.card-img img {
  width: 100%;
  border-radius: 8px;
}

.card-img:hover {
  transform: translateY(-5px);
}

.just-card {
  background-color: #fff;
  padding: 20px;
  width: 450px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}


.card {
  background-color: #fff;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: left;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card h4 {
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
}

.card p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

.card img {
  border-radius: 6px;
  width: 100%;
  height: auto;
}

.scroll-container .card p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .footer-left,
  .footer-right {
    width: 100%;
    text-align: center;
  }
  .footer-right {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }
  .section {
    padding: 20px;
  }
  .cards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%;
  }
}

.popup {
  display: none;
  position: fixed;
  top: 10%;
  right: 20px;
  width: 200px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.popup .overlay {
  display: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup .popup-content {
  position: relative;
}

.popup .popup-content h2 {
  margin: 0;
  font-size: 18px;
}

.popup .popup-content .btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.popup .popup-content .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.popup .popup-content .close-btn:hover {
  color: #f00;
}

.scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: 10px;
}

.scroll-item-none {
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

.scroll-item {
  display: inline-block;
  width: 117px;
  height: 208px;
  background-color: #ddd;
  margin-right: 10px;
  text-align: center;
  line-height: 150px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.scroll-item:hover {
  transform: translateY(-5px);
}

.scroll-item-large {
  display: inline-block;
  width: 316px;
  height: 178px;
  background-color: #ddd;
  margin-right: 10px;
  text-align: center;
  line-height: 150px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.scroll-item-large:hover {
  transform: translateY(-5px);
}

.scroll-item-news {
  display: inline-block;
  width: 350px;
  height: 350px;
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.scroll-item-news:hover {
  transform: translateY(-5px);
}

.dark-theme #app {
  background-color: #333;
  color: #f0f0f0;
}

.dark-theme {
  background-color: #333;
  color: #f0f0f0;
}

.dark-theme .popup {
  background-color: #444;
}

.dark-theme .popup .popup-content ul li a {
  color: #80bfff;
}

.dark-theme .popup .popup-content .btn-theme-toggle {
  background-color: #444;
  color: #80bfff;
}

.dark-theme .popup .popup-content .btn-theme-toggle:hover {
  background-color: #333;
}

.dark-theme .content h1 {
  color: #fff;
}

.dark-theme .content h2 {
  color: #ccc;
}

.dark-theme .section h3 {
  color: #fff;
}

.dark-theme .section h3::after {
  background-color: #ccc;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.image-text-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Ajuste conforme necessário */
  margin: 10px auto;
}

.image-text-container-i {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Ajuste conforme necessário */
  margin: 10px auto;
}

.image-text-container img {
  width: 70%; /* Ajuste conforme necessário */
  height: auto;
  border-radius: 8px;
}

.image-text-container-i img {
  width: 70%; /* Ajuste conforme necessário */
  height: auto;
  border-radius: 8px;
}

.overlay-text {
  position: absolute;
  left: 0;
  padding-left: 20px;
  color: white;
  text-align: left;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.overlay-text-i {
  position: absolute;
  right: 0;
  padding-right: 20px;
  color: white;
  text-align: right;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.overlay-text h1 {
  font-size: 36px;
  margin: 0;
  font-weight: 700;
}

.overlay-text p {
  font-size: 18px;
  margin: 0px 0 0 0;
}

.overlay-text-i h1 {
  font-size: 36px;
  margin: 0;
  font-weight: 700;
}

.overlay-text-i p {
  font-size: 18px;
  margin: 0px 0 0 0;
}
</style>
