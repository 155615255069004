<template>
        <div class="footer">
        <div class="footer-content">
            <!-- Seção Sobre com Logo, Texto e Ícones Sociais -->
            <div class="footer-left">
                <img src="https://via.placeholder.com/80" alt="Logo">
                <p>Somos um grupo de pessoas empenhadas em produzir e distribuir bons addons / jogos de forma acessivel e para todos.</p>
                <div class="social-icons">
                    <a href="#" target="_blank"><i class="fab fa-discord"></i></a>
                    <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                    <a href="#" target="_blank"><i class="fab fa-youtube"></i></a>
                </div>
            </div>

            <!-- Seção de Acesso Rápido e Links Institucionais -->
            <div class="footer-right">
                <div class="quick-access">
                    <h4>Acesso Rápido</h4>
                    <ul>
                        <li><a href="#">Início</a></li>
                        <li><a href="#">Serviços</a></li>
                        <li><a href="#">Contato</a></li>
                        <li><a href="#">Sobre Nós</a></li>
                    </ul>
                </div>
                <div class="institutional">
                    <h4>Institucional</h4>
                    <ul>
                        <li><a href="#">Termos de Serviço</a></li>
                        <li><a href="#">Política de Privacidade</a></li>
                        <li><a href="#">Suporte</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            © 2024 Sunrise Studios. Todos os direitos reservados.
        </div>
    </div>
</template>

<script>
export default {
  name: 'SFooter'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
    padding: 40px 20px;
    background-color: #333;
    color: white;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.footer-left {
    width: 25%;
    text-align: left;
    animation: fadeIn 1s ease;
}

.footer-left img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.footer-left p {
    margin-bottom: 20px;
    font-size: 14px;
    color: #ccc;
}

.social-icons a {
    color: white;
    margin-right: 10px;
    font-size: 18px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
    color: #ddd;
    transform: scale(1.1);
}

.footer-right {
    display: flex;
    justify-content: space-between;
    width: 60%;
    animation: fadeIn 1.5s ease;
}

.footer-right h4 {
    margin-bottom: 15px;
    font-size: 16px;
    color: #fff;
}

.footer-right ul {
    list-style: none;
    padding: 0;
}

.footer-right ul li {
    margin-bottom: 10px;
}

.footer-right ul li a {
    color: #ccc;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

.footer-right ul li a:hover {
    color: #ddd;
}

.footer-bottom {
    margin-top: 30px;
    text-align: center;
    font-size: 14px;
    color: #999;
}

@media (max-width: 768px) {
    .footer-left, .footer-right {
        width: 100%;
        text-align: center;
    }
    .footer-right {
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
    }
}
</style>
